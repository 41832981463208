import GraphIcon from '-!svg-react-loader!images/svg/Graph.svg';
import InfoIcon from '-!svg-react-loader!images/svg/info.svg';
import { updateSiteState } from 'actions/uiState';
import { requestDictionary } from 'actions/V2/dictionary';
import { requestGraph } from 'actions/V2/graph';
import { cleanKiosk, Reading, requestKioskDetail } from 'actions/V3/kiosks';
import { Button as AntButton, Icon, Tooltip } from 'antd';
import classnames from 'classnames';
import CollectionSelector from 'components/CollectionSelector';
import Video from 'components/Video';
import { t } from 'i18next';
import { isNumber } from 'lodash';
import moment from 'moment';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { lastReqArgs } from 'sagas/V2/graph';
import { LEVEL_DICT } from 'utils/constant';
import { calcValueByFormula, changeColor } from 'utils/helper';
import levelTooltip from 'utils/level';
import { KioskCollectionName } from '../../utils/enums';
import customKioskSettings from '../../vendors/custom';
import CovidIndexKiosk from './CovidIndexKiosk';
import './index.css';
import InfoModal from './InfoModal';
import KioskForm from './KioskForm';
import KioskModal from './KioskModal';
import RenderTemplateJPMC from './RenderTemplateJPMC';
import { P, StateProps } from './type';

let lastTheme: string;
let kioskInterval: number;
/**
 * 由于hook 内部函数immuteable 的特性，内部除了state 之外的状态基本无法动态化
 * interval 函数自身创建时已经决定了其绑定当时上下文的状态
 * 因此interval 的请求无法获取到最新 id 和 overlay 状态
 * 由于值本身都是string 是无法通过索引进行跟踪的
 * 为了每次请求的状态都是最新的，需要利用一个索引对象来保存当前页面的真实状态
 * interval 函数初始化后利用索引不变内容可变的特性可以访问到最新的页面状态
 * 做法上类似window.xxx 的顶层变量，在hook中直接做，需要使用state 才能更新其状态，否则仍只能获取最初的状态
 * 因此在函数外部作用相对简单
 * 整个页面文件有自己的作用域
 * 因此外部变量使用过不会自己主动释放（效果和闭包一样）
 * 下次重新渲染是外部变量仍可以访问到上一次的变量
 * 如果需要记得在unmount 环节重置状态
 */

const RealTimeInterval = 10 * 60 * 1000;

const ReadingWrap: React.SFC<{
  lastUpdate?: string;
  isPro: boolean;
}> = React.memo(({ isPro, children, lastUpdate }) => {
  return isPro ? (
    <>{children}</>
  ) : (
    <div className='reading-row-with_time'>
      {children}
      <div className='last-update'>
        {lastUpdate &&
          t('last update at', { date: moment(lastUpdate).fromNow() || '' })}
      </div>
    </div>
  );
});

const Kiosk: React.SFC<P> = React.memo((props) => {
  const {
    kiosk,
    locked,
    indicators,
    match: {
      params: { collection_id, id }
    },
    history,
    dispatch
  } = props;

  const [muted, setMuted] = React.useState(true);
  const [overlay, setOverlay] = React.useState<'Graph' | 'Collection' | ''>('');
  const [modalOpen, setModalOpen] = React.useState(false);
  const observeArgs = React.useRef({
    id: '',
    overlay: ''
  });

  const openModal = () => {
    setModalOpen(true);
  };


  const tryToLogin = () => {
    dispatch(
      updateSiteState({
        redirectURL: `${window.location.pathname}${window.location.search}`
      })
    );
    history.push('/user/sign_in');
  };

  const changeCollection = useCallback((cid: number) => {
    setOverlay('');
    history.replace(cid < 0 ? `/${id}` : `/${id}/${cid}`);
  }, [history, id]);

  // 请求kiosk
  const fetchKiosk = useCallback(() => {
    const params = {
      kiosk_id: id,
      unlock_token: localStorage.getItem(`kiosk-${id}`)
    };

    dispatch(requestKioskDetail(params));
  }, [dispatch, id]);

  // 定时任务
  const setIntervalTask = () => {
    if (kioskInterval) {
      window.clearInterval(kioskInterval);
    }
    kioskInterval = window.setInterval(() => {
      const observeID = observeArgs.current.id || id;
      const params = {
        kiosk_id: observeID,
        unlock_token: localStorage.getItem(`kiosk-${observeID}`)
      };

      dispatch(requestKioskDetail(params));

      if (observeArgs.current.overlay === 'Graph') {
        dispatch(requestGraph({ ...lastReqArgs, kioskInterval: true }));
      }
    }, RealTimeInterval);
  };

  // 根据id 变化查询最新的kiosk 同时更新定时器任务
  React.useEffect(() => {
    dispatch(requestDictionary({ name: 'indicators' }));
    fetchKiosk();
  }, [dispatch, fetchKiosk, id]);

  React.useEffect(() => {
    observeArgs.current.id = id;
    observeArgs.current.overlay = overlay;
  }, [id, overlay]);

  // will unmount
  React.useEffect(() => {
    return () => {
      if (lastTheme !== window.PRIMARY_COLOR) {
        changeColor(window.PRIMARY_COLOR);
      }

      if (kioskInterval) {
        window.clearInterval(kioskInterval);
      }
      lastTheme = '';
      document.title = 'QLEAR WEB';
      dispatch(cleanKiosk());
    };
  }, [dispatch]);

  // 根据kiosk 对象判断是否渲染主题
  React.useEffect(() => {
    if (kiosk) {
      setIntervalTask();
      document.title = kiosk.location_name;
      const theme = kiosk.theme_color || window.PRIMARY_COLOR;
      if (lastTheme !== theme) {
        const fontColor = kiosk.font_color || '#ffffff';
        const dataPanelOpacity = isNumber(kiosk.data_panel_opacity)
          ? kiosk.data_panel_opacity
          : 0.9;
        changeColor(theme, fontColor, dataPanelOpacity);
        lastTheme = theme;
      }
    }
  }, [kiosk, setIntervalTask]);

  // computed 对象计算 当前 collection | average
  // template 2 时，会有 kiosk.collection 数据
  const current = React.useMemo(
    () =>
      kiosk &&
      (kiosk.collection ||
        (collection_id
          ? kiosk.collections.find((item) => item.id === +collection_id)
          : kiosk.average)),
    [kiosk, collection_id]
  );

  const lastUpdate = React.useMemo(
    () =>
      current &&
      current.last_reading_time &&
      moment(current.last_reading_time).fromNow(),
    [current]
  );

  const collectionsList = React.useMemo(
    () =>
      kiosk && kiosk.template_key !== 'fixed_panels'
        ? (kiosk.collections as any).concat(
          kiosk.pro && kiosk.average ? [{ ...kiosk.average, id: -1 }] : []
        )
        : [],
    [kiosk]
  );

  React.useEffect(() => {
    if (
      collectionsList[0] &&
      !collectionsList.find((v) => v.id.toString() === (collection_id || KioskCollectionName.LOCATION_AVERAGE))
    ) {
      changeCollection(collectionsList[0].id);
    }
  }, [changeCollection, collection_id, collectionsList]);

  if (locked) {
    return (
      <KioskForm kiosk={locked} tyrLogin={tryToLogin} request={fetchKiosk} />
    );
  }

  if (!kiosk || !indicators) {
    return null;
  }

  const statusDictionary = LEVEL_DICT();

  const navToOutdoor = (id) => {
    setOverlay('');
    history.push(`/${id}`);
  };

  const renderLevel = (obj: Reading) => {
    return (
      <Tooltip
        title={
          levelTooltip[obj.data_channel] &&
          levelTooltip[obj.data_channel](
            obj.level,
            obj.unit || obj.default_unit
          )
        }
      >
        <span
          className={classnames('kiosk-level', {
            [`level-${obj.level}`]: obj.level
          })}
        >
          {statusDictionary[obj.level!]}
        </span>
      </Tooltip>
    );
  };

  const renderReadings = () => {
    if (!current) return null;
    const primaryReading = current.readings.find(
      (item) => item.data_channel === current.primary_data_channel
    );

    const readingDict: { [P: string]: number } = current.readings.reduce(
      (p, n) => {
        p[n.data_channel] = n.value;
        return p;
      },
      {} as any
    );

    const secondReadings = current.readings.filter(
      (item) => item.data_channel !== current.primary_data_channel
    );

    const outdoorReading =
      kiosk.outdoor &&
      kiosk.outdoor.readings.find(
        (item) => item.data_channel === current.primary_data_channel
      );

    return (
      <div className='summary-readings'>
        {primaryReading && (
          <div className='primary-reading'>
            <div className='primary-reading--header kiosk-indicator-name'>
              {indicators[primaryReading.data_channel]}
            </div>
            <div
              className='primary-reading--body'
              style={{ fontFamily: kiosk.font }}
            >
              {calcValueByFormula(primaryReading, readingDict)}
            </div>
            <div className='primary-reading--unit'>
              {primaryReading.use_aqi
                ? 'AQI'
                : primaryReading.unit || primaryReading.default_unit}
            </div>
            <div className='primary-reading--data-wrap'>
              <div className='primary-reading--data-row'>
                <div className='left'>
                  {/* {current.stale && (
                    <span className='stale-label'>{t('STALE')}</span>
                  )} */}
                  {kiosk.show_health_rating === true && renderLevel(primaryReading)}
                  <span className='kiosk-data-name'>
                    {kiosk.outdoor_flag
                      ? t('filter.outdoor')
                      : t('filter.indoor')}
                  </span>
                </div>
                <div className='right' />
              </div>
              {outdoorReading && (
                <div className='primary-reading--data-row'>
                  <div className='left'>
                    {kiosk.show_health_rating === true && renderLevel(outdoorReading)}
                    <Tooltip title='Go to Outdoor'>
                      <a
                        className='kiosk-data-name'
                        onClick={() => navToOutdoor(kiosk.outdoor!.id)}
                      >
                        {t('filter.outdoor')}
                      </a>
                    </Tooltip>
                  </div>
                  <div className='right'>
                    <span className='kiosk-indicator-value'>
                      {outdoorReading.value}
                    </span>
                    <span className='kiosk-indicator-unit'>
                      {outdoorReading.use_aqi ? 'AQI' : outdoorReading.unit}
                    </span>
                  </div>
                </div>
              )}
              {!kiosk.outdoor_flag && (
                <div className='primary-reading--data-row'>
                  <div className='left'>
                    <span className='kiosk-data-name'>{t('kiosk.bto')}</span>
                  </div>
                  <div className='right'>
                    {current.bto ? (
                      <div>
                        <span
                          className='kiosk-indicator-value'
                          style={{ fontFamily: kiosk.font }}
                        >
                          {current.bto}
                        </span>
                        <span className='kiosk-indicator-unit'>x</span>
                      </div>
                    ) : (
                      <span className='kiosk-indicator-value'>—</span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {secondReadings.map((item, index) => (
          <ReadingWrap
            key={`${item.data_channel}-${index}`}
            lastUpdate={item.time}
            isPro={kiosk.pro}
          >
            <div className='second-reading--data-wrap'>
              <div className='kiosk-indicator-name'>
                {indicators[item.data_channel]}
              </div>
              <div className='second-reading--status'>
                {kiosk.show_health_rating === true && renderLevel(item)}
                <span
                  className='kiosk-indicator-value'
                  style={{ fontFamily: kiosk.font }}
                >
                  {calcValueByFormula(item, readingDict)}
                </span>
                <span className='kiosk-indicator-unit'>
                  {item.use_aqi ? 'AQI' : item.unit || item.default_unit}
                </span>
              </div>
            </div>
          </ReadingWrap>
        ))}
      </div>
    );
  };

  const panelLeft = kiosk.data_panel_position === 'left';
  const { cushman } = customKioskSettings
  const isMobile = window.innerWidth <= 576;

  if (kiosk.template_key === customKioskSettings.covid_index.template_key) {
    return <CovidIndexKiosk kiosk={kiosk} collectionsList={collectionsList} />;
  } else if (kiosk.template_key === customKioskSettings.jpmc.template_key) {
    return <RenderTemplateJPMC kiosk={kiosk} />;
  } else
    //need to make a component for the JSX kiosk below
    return (
      <div className='kiosk-body'>
        <div
          className='kiosk-page'
          style={{ justifyContent: `flex-${panelLeft ? 'start' : 'end'}`, height: kiosk.id === cushman.id ? cushman.height : '' }}
        >
          <div
            className='kiosk-page--container-with-background'
            style={{
              display: kiosk.background_full_screen ? 'block' : 'none',
              backgroundImage:
                !kiosk.enable_background_video && kiosk.background_image
                  ? `url(${kiosk.background_image})`
                  : undefined
              // That's where the picture must be updated above
            }}
          >
            {kiosk.background_full_screen &&
              kiosk.enable_background_video &&
              kiosk.background_video && (
                <Video url={kiosk.background_video} mute={muted} />
              )}
          </div>
          <div
            className='kiosk-page--summary'
            style={{ order: panelLeft ? -1 : 10 }}
          >
            <div className='summary-header'>
              <div className='location-logo'>
                {kiosk.logo_image && <img src={kiosk.logo_image} alt='' />}
              </div>
              <div className='location-name'>
                <h2 style={{ fontFamily: kiosk.font }}>
                  {kiosk.location_name}
                </h2>
              </div>
              <div className='summary-selector'>
                {collectionsList.length ? (
                  <CollectionSelector
                    defaultValue={collection_id || KioskCollectionName.LOCATION_AVERAGE}
                    handleChange={changeCollection}
                    collectionsList={collectionsList}
                  />
                ) : (
                  t('no collection no average')
                )}
              </div>
            </div>
            <div className='flex-h'>
              <div className='summary-location'>
                <Icon type='environment' />
                <Tooltip title={kiosk.time_zone}>
                  <span>{kiosk.city_name}</span>
                </Tooltip>
              </div>
              {kiosk.pro && (
                <div className='summary-update'>
                  {kiosk.workspace_id === 59 && (
                    <span className='current-ts'>
                      {moment().format('YYYY-MM-DD HH:mm')}
                    </span>
                  )}
                  {lastUpdate &&
                    t('last update at', { date: lastUpdate || '' })}
                  <div>{t('Average')}</div>
                </div>
              )}
            </div>
            {!!collectionsList.length && renderReadings()}
          </div>
          {kiosk.background_full_screen ? (
            <KioskModal
              overlay={overlay}
              changeCollection={changeCollection}
              close={() => setOverlay('')}
              isFull={kiosk.background_full_screen}
              current={current}
              kiosk={kiosk}
            />
          ) : (
            <div className='kiosk-modal'>
              <div
                className='kiosk-modal-background'
                style={
                  !kiosk.enable_background_video && kiosk.background_image
                    ? {
                      backgroundImage: `url(${kiosk.background_image})`
                    }
                    : {}
                }
              >
                {kiosk.enable_background_video && kiosk.background_video && (
                  <div className='kiosk-page-video'>
                    <Video url={kiosk.background_video} mute={muted} />
                  </div>
                )}
              </div>
              <KioskModal
                overlay={overlay}
                changeCollection={changeCollection}
                close={() => setOverlay('')}
                isFull={kiosk.background_full_screen}
                current={current}
                kiosk={kiosk}
              />
            </div>
          )}
          {(!isMobile || (isMobile && !overlay)) && (
            <div
              className='kiosk-page--floating-menu'
              style={
                panelLeft
                  ? { right: 10, left: 'auto' }
                  : { left: 10, right: 'auto', top: 42 }
              }
            >
              {kiosk.enable_background_video && (
                <Tooltip
                  placement={panelLeft ? 'left' : 'right'}
                  title={muted ? t('volume on') : t('volume off')}
                >
                  <AntButton
                    type='primary'
                    shape='circle'
                    size='large'
                    onClick={() => setMuted(!muted)}
                  >
                    <i className='material-icons top-icon'>
                      {muted ? 'volume_off' : 'volume_up'}
                    </i>
                  </AntButton>
                </Tooltip>
              )}

              {kiosk.workspace_id === 2 && ( // TODO Need to be updated with the workspace_i of DKU
                <Tooltip
                  placement={panelLeft ? 'left' : 'right'}
                  title={t('navbar.information')}
                >
                  <AntButton
                    disabled={!current}
                    type='primary'
                    shape='circle'
                    size='large'
                    onClick={() => openModal()}
                  >
                    <InfoIcon />
                  </AntButton>
                </Tooltip>
              )}
              <Tooltip
                placement={panelLeft ? 'left' : 'right'}
                title={t('navbar.graph')}
              >
                <AntButton
                  disabled={!current}
                  type='primary'
                  shape='circle'
                  size='large'
                  onClick={() => setOverlay('Graph')}
                >
                  <GraphIcon />
                </AntButton>
              </Tooltip>
              <Tooltip
                placement={panelLeft ? 'left' : 'right'}
                title={t('Collections')}
              >
                <AntButton
                  disabled={!current}
                  type='primary'
                  shape='circle'
                  size='large'
                  onClick={() => setOverlay('Collection')}
                >
                  <i className='material-icons top-icon'>group_work</i>
                </AntButton>
              </Tooltip>
            </div>
          )}
        </div>
        {modalOpen && (
          <InfoModal
            close={() => setModalOpen(false)}
            kiosk={kiosk}
            modalOpen={modalOpen}
          />
        )}
      </div>

    );
});

const mapStateToProps: MapState<StateProps> = ({
  V2: {
    dictionary: { indicators }
  },
  V3: { kiosks }
}) => ({
  kiosk: kiosks.detail,
  locked: kiosks.lock,
  indicators
});

export default withRouter(connect(mapStateToProps)(Kiosk));
