import { KioskDetail } from 'actions/V3/kiosks'
import { Icon } from 'antd'
import React from 'react'
import { CSSTransition } from 'react-transition-group'

type P = {
  close: () => void,
  kiosk: KioskDetail,
  modalOpen: boolean
}

const ModalInfo: React.SFC<P> = React.memo(props => {
  const { close, kiosk, modalOpen } = props

  return (
    <CSSTransition classNames='appear' timeout={300} unmountOnExit={true} in={modalOpen === true}>
      <div className='kiosk-modal-info'>
        <div className='kiosk-page--close-btn' onClick={close}>
          <Icon type='close' theme='outlined' />
        </div>
        <div className='kiosk-modal-info-content'>
          <div dangerouslySetInnerHTML={{ __html: kiosk.kiosk_information }} />
        </div>
        <div className='kiosk-modal-info-footer'>
          <button className='ant-btn ant-btn-primary' onClick={close}>
            Close
          </button>
        </div>
      </div>
    </CSSTransition>
  )
})
export default ModalInfo
