import { Icon } from 'antd'
import * as React from 'react'
import { CSSTransition } from 'react-transition-group'

type P = {
  close: () => void
}

const ModalInfo: React.SFC<P> = React.memo(props => {
  const { close } = props

  const workspaceLocation = [{ build_number: 0, abbrevation: 'abbrevation', buiding_name: 'building name', floor: '1' }]

  return (
    <CSSTransition classNames='appear' timeout={300} unmountOnExit={true} in={true}>
      <div>
        <div className='kiosk-page--close-btn' onClick={close}>
          <Icon type='close' theme='outlined' />
        </div>
        <div className='body'>
          {workspaceLocation.map((workspace, i) => {
            return (
              <React.Fragment key={`workspace-${i}`}>
                <div className='workspace-ov-activity-line'>{workspace.build_number}</div>
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </CSSTransition>
  )
})

export default ModalInfo
